.modal
{
    background: #111d;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: backgroundFadeIn;
    animation-duration: .25s;
}

.picture-visualize
{
    height: 70%;
    margin: 5em;
    animation-name: zoomPicture;
    animation-duration: .5s;
    animation-timing-function: var(--spring-easing);
}

@keyframes backgroundFadeIn
{
    0% { background: #1110 }
    100% { background: #111d }
}

@keyframes zoomPicture
{
    0% { transform: scale(50%) }
    100% { transform: scale(100%) }
}

:root {
--spring-easing: linear(
    0, 0.009, 0.035 2.1%, 0.141, 0.281 6.7%, 0.723 12.9%, 0.938 16.7%, 1.017,
    1.077, 1.121, 1.149 24.3%, 1.159, 1.163, 1.161, 1.154 29.9%, 1.129 32.8%,
    1.051 39.6%, 1.017 43.1%, 0.991, 0.977 51%, 0.974 53.8%, 0.975 57.1%,
    0.997 69.8%, 1.003 76.9%, 1.004 83.8%, 1
);
--spring-duration: 1.333s;
}