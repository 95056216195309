* {
    margin: 0;
    padding: 0;
}

body {
    background: #262626;
    overflow-x: hidden;
    overflow: hidden;
}

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  list-style-type: none;
  transform: scale(1.75)
}

.hex {
  position: relative;
  visibility:hidden;
  outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
  transition: transform .2s ease-out;
  background-color: red;
}

.hex:hover
{
  transform: scale(1.25);
  z-index: 10;
}

.hex::after{
  content:'';
  display:block;
  padding-bottom: 86.602%;  /* =  100 / tan(60) * 1.5 */
}
.hexIn{
  position: absolute;
  width:96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin:0 2%;
  overflow: hidden;
  visibility: hidden;
  outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
      -ms-transform: rotate3d(0,0,1,-60deg) skewY(30deg);
          transform: rotate3d(0,0,1,-60deg) skewY(30deg);
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline:1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hexLink {
    display:block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    overflow: hidden;
    -webkit-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
        -ms-transform: skewY(-30deg) rotate3d(0,0,1,60deg);
            transform: skewY(-30deg) rotate3d(0,0,1,60deg);
}

/*** HEX CONTENT **********************************************************************/
.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  background: #262626;
  -webkit-transform: rotate3d(0,0,0,0deg);
      -ms-transform: rotate3d(0,0,0,0deg);
          transform: rotate3d(0,0,0,0deg);
}

.hex h1, .hex p {
  width: 100%;
  padding: 5%;
  box-sizing:border-box;
  background-color: rgba(0, 128, 128, 0.8);
  font-weight: 300;
  -webkit-transition:  -webkit-transform .2s ease-out, opacity .3s ease-out;
          transition:          transform .2s ease-out, opacity .3s ease-out;
}
.hex h1 {
  bottom: 50%;
  padding-top:50%;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform:translate3d(0,-100%,0);
      -ms-transform:translate3d(0,-100%,0);
          transform:translate3d(0,-100%,0);
}
.hex h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}
.hex p {
  top: 50%;
  padding-bottom:50%;
  -webkit-transform:translate3d(0,100%,0);
      -ms-transform:translate3d(0,100%,0);
          transform:translate3d(0,100%,0);
}


/*
function mediaRules(countFirstLine)
{
  let count = countFirstLine

  let width = (1 / count) * 100 + '%'
  let i = count - 3
  let nthChild = `${3+i*2}n+${3+i}`
  let marginLeft = (1 / count) * 50 + '%'

  return {width : width, nthChild : nthChild, marginLeft : marginLeft}
}
mediaRules(10)
*/


@media (min-width:calc(1201px)) {
  .hex {
    width: 10%;
  }
  .hex:nth-child(18n+10){
    margin-left:5%;
  }
  .banner-title
  {
    font-size: 2.0em;
    letter-spacing : 1.0em;
  }
}

@media (max-width: 1200px) and (min-width:901px) {
  .hex {
    width: 11.11%;
  }
  .hex:nth-child(16n+9){
    margin-left:5.555%;
  }
  .banner-title
  {
    font-size: 1.75em;
    letter-spacing : 0.75em;
  }
}

@media (max-width: 900px) and (min-width:601px) {
  .hex {
    width: 12.5%;
  }
  .hex:nth-child(14n+8){
    margin-left:6.25%;
  }
  .banner-title
  {
    font-size: 1.25em;
    letter-spacing : 0.75em;
  }
}

@media (max-width: 600px) and (min-width: 401px) {
  #hexGrid
  {
    /* transform: scale(1.75) */
  }
  .hex {
    width: 14.285%;
  }
  .hex:nth-child(12n+7){
    margin-left:7.14%;
  }
  .banner-title
  {
    font-size: .75em;
    letter-spacing : 0.75em;
  }
}

@media (max-width: 400px){
  #hexGrid
  {
    /* transform: scale(1.75) */
  }
  .hex {
    width: 25.0%;
  }
  .hex:nth-child(7n+5){
    margin-left:12.5%;
  }
  .banner-title
  {
    font-size: 0.5em;
    max-width: 75%;
    letter-spacing : 0.75em;
  }
}

.div-preview-normal
{
  background-color: #0000;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .25s linear;
  z-index: 10;
}

.div-preview-active
{
  width: 100%;
  height: 100%;
  background-color: #111e;
}

.img-preview
{
  width: 0%;
  transition: transform .25s linear;
  transform : scale(0);
  height: auto;
  cursor: pointer;
}

.img-preview-active
{
  transform : scale(1);
  width: 50%;
}
a:link {
  text-decoration: none;
  color: white;
  transition: color .1s linear;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:active {
  text-decoration: none;
  color: white;
}

.container-banner-title
{
  position : absolute;
  top : 0;
  display : flex;
  align-items : center;
  justify-content : center;
  width : 100%;
  height : 100%;
  pointer-events: none;
}

.banner-title
{
  background : white;
  padding : 1em;
  pointer-events: auto;
  text-align: center;
}